<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>FD6 Name</th>
          <th>FD6 Code</th>
          <th>Party Name</th>
          <th>Interval</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in projectList.data" :key="i">
          <td>{{ item.name }}</td>
          <td>{{ item.code }}</td>
          <td>{{ item.party?.full_name }}</td>
          <td>{{ item.interval }}</td>
          <td>{{ item.start_date }}</td>
          <td>{{ item.end_date }}</td>
          <td>{{ item.status }}</td>
          <td>
            <span @click="handleEdit(item.id)" style="cursor: pointer">
              Edit
            </span>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!projectList?.data?.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {defineProps} from "vue";
const route = useRoute();
const router = useRouter();

const props = defineProps({
  projectList: Object,
});

function handleEdit(fd6Id) {
  router.push({
    name: 'fd6-edit',
    params: {
      ...route.params,
      fd6Id
    },
    query: route.query
  });
}
</script>
